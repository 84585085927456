import { useEffect, useState } from "react"
import { enableProvisioning, getProvisioningStatus } from "services/apiService";
import { Toggle, Dialog, DialogFooter } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import IconBotSparkle from "assets/icons/icon-bot-sparkle.svg";
import { t } from "i18next";
import { IconDismiss } from "components/Icons/IconDismiss";
import "assets/styles/pages/ControlPanel.scss";

export const TabProvisioning = () => {
    const [provisioningStatus, setProvisioningStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchAdminConfigStatus = async () => {
            try {
                const response = await getProvisioningStatus();
                if (response) {
                    setProvisioningStatus(response.ProvisioningEnabled);
                }
                setIsLoading(false);
            }
            catch (error) {
                console.error("Failed to fetch Teams Bot status", error);
                setIsLoading(false);
            }
        };

        fetchAdminConfigStatus();
    }, []);

    const onToggleChange = (enabled: boolean) => {
        // console.log("toggle status:", enabled);
        if (!enabled) {
            setIsModalOpen(true); // Show confirmation modal
        } else {
            updateProvisioningStatus(enabled); // Directly update if enabling
        }
    };

    const updateProvisioningStatus = async (enabled: boolean) => {
        try {
            await enableProvisioning(enabled);
            setProvisioningStatus(enabled);
        } catch (error) {
            console.error("Failed to update Provisioning status", error);
        }
    };

    const handleModalDismiss = () => {
        setIsModalOpen(false);
    };

    const handleModalConfirm = () => {
        updateProvisioningStatus(false);
        setIsModalOpen(false);
    };

    if (isLoading) {
        return <div className="deliwi-spinner"></div>;
    }

    return <>
        <div className="admin-teams__intro">
            <img src={IconBotSparkle} className="logo-bot" alt="" />
            <div className="admin-teams__intro--texts">
                <div className="admin-teams__intro--top">
                    <h2 className="text-style--Title2 text-style--semibold text-color--brand10 admin-teams__intro--title">{t("ProvisioningSettings")}</h2>
                </div>
                <div className="admin-teams__intro--bottom">
                    <p className="text-style--Body1 text-style--bold text-style--nowrap">{t("WhatIsThis")}</p>
                    <p className="text-style--Body1">{t("ProvisioningExplanation0")} <br /> <br /> {t("ProvisioningExplanation1")}</p>
                </div>
            </div>
        </div >
        <div className="admin-box">
            <h3 className="text-style--Subtitle1 text-color--brand10 mb-16">{t("ProvisioningManagement")}</h3>
            <p className="text-style--Helper mb-8">{t("ProvisioningOnOff")}</p>
            <div className="admin-teams__toggle mb-16">
                <Toggle checked={provisioningStatus} className="" onChange={(_e, checked) => onToggleChange(checked ?? false)} label={t("ProvisioningSync")} onText="on" offText="off" />
            </div>
            <div className="info-text">
                <p className="text-style--semibold">{t("WontCommunicate")}</p>
            </div>
        </div>

        <Dialog
            hidden={!isModalOpen}
            onDismiss={handleModalDismiss}
            minWidth={640}
            dialogContentProps={{
                showCloseButton: true,
                title: t("AreYouSure"),
            }}
            modalProps={{
                className: "admin-dialog",
            }}
        >
            <div className="box-gray mb-32">
                <p className="text-style--Body1 text-style--semibold mb-16">{t("TurningFunctionalityOff")}</p>
                <div className="info-text">
                    <p className="text-style--semibold">{t("TurningFunctionalityOffMeansProvisioning")}</p>
                </div>
            </div>
            <DialogFooter>
                <DeliwiButton className="button-style--outline button-size--large" onClick={handleModalDismiss} text={t("Dismiss")}>
                    <IconDismiss className="icon-size--16" />
                </DeliwiButton>
                <DeliwiButton className="button-style--delete button-size--large" onClick={handleModalConfirm} text={t("YesImSure")} />
            </DialogFooter>
        </Dialog>
    </>
}